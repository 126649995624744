/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { TableControlContext } from "@src/shipments/context/TableControlContext";
import { ShipmentsTableProvider } from "@src/shipments/context/ShipmentsTableContext";
import { ModalsProvider } from "@src/shipments/context/ShipmentsTableModalsContext";
import { ShipmentFiltersProvider } from "@src/shipments/context/ShipmentFiltersContext";
import { ShipmentsCoreDataProvider } from "@src/shipments/context/ShipmentsCoreData";
import { ShipmentSelectionProvider } from "@src/shipments/context/ShipmentSelectionContext";
import { DispatchPreviewProvider } from "@src/shipments/context/DispatchPreviewContext";
import { ShipmentKeyboardControlProvider } from "@src/shipments/context/ShipmentKeyboardControlContext";
import OrdersPage from "@src/shipments/components/OrdersPage/OrdersPage";
import { CreateOrderContextProvider } from "@src/orders/create/utils/CreateOrderContextProvider";

function orders() {
  return (
    <TableControlContext sortKey="orders">
      <ShipmentFiltersProvider>
        <ShipmentsCoreDataProvider>
          <DispatchPreviewProvider>
            <ShipmentSelectionProvider>
              <ShipmentKeyboardControlProvider>
                <ShipmentsTableProvider>
                  <ModalsProvider>
                    <OrdersPage />
                  </ModalsProvider>
                </ShipmentsTableProvider>
              </ShipmentKeyboardControlProvider>
            </ShipmentSelectionProvider>
          </DispatchPreviewProvider>
        </ShipmentsCoreDataProvider>
      </ShipmentFiltersProvider>
    </TableControlContext>
  );
}

export default orders;
